/* eslint-disable no-prototype-builtins */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Channels, channels } from 'src/app/appdata/shared.data';
import { Store } from '@ngrx/store';
import { Subject, takeUntil, BehaviorSubject } from 'rxjs';
import * as roleactions from 'src/app/control-center/role-management/store/role.actions';
import { selectRoleDataDtls } from 'src/app/control-center/role-management/store/role.selectors';
import { AlertService } from 'src/app/alert/alert.service';
import { customerPermissionsData } from 'src/app/control-center/role-management/role.data';
import { SharedService } from 'src/app/services/shared.service';
import { Params, Router, ActivatedRoute } from '@angular/router';
import {
  selectProfilesDtlsData,
  selectSsoProfilesDtlsData,
} from 'src/app/store/common.selectors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss'],
})
export class AddRoleComponent implements OnInit, AfterViewInit, OnDestroy {
  displayTemplate: TemplateRef<any>;
  createRole: FormGroup;
  rolePermissions: any = JSON.parse(JSON.stringify(customerPermissionsData));
  rolesAclList = new BehaviorSubject(Object.keys(this.rolePermissions));
  channelList: Channels[] = channels;
  account_dtls: any = {
    account_id: null,
    bsp_id: null,
    waba_no: null,
    channel: null,
  };
  updateRoleItem: any;
  isUpdateRole: boolean = false;
  userDetails: any;
  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('createRoleTemplate') private createRoleTemplate: TemplateRef<any>;
  @ViewChild('createRoleSuccess') private createRoleSuccess: TemplateRef<any>;

  constructor(
    private fb: FormBuilder,
    private readonly store: Store,
    private router: Router,
    private alertMsg: AlertService,
    private shareservice: SharedService,
    private activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.createRole = this.fb.group({
      roleName: [null, Validators.required],
      roleDesc: [null, Validators.required],
    });

    if (this.router.url.includes('edit')) {
      this.activatedRoute.params.subscribe((params: Params) => {
        const updaterole_id = params['id'];
        this.store.dispatch(roleactions.viewRoleDetails({ id: updaterole_id }));
      });
    }

    this.shareservice.selectedBA$.subscribe((value: any) => {
      if (value) {
        this.account_dtls.account_id = value.channel_credentials.account_id;
        this.account_dtls.bsp_id = value.channel_credentials.bsp_id;
        this.account_dtls.waba_no = value.channel_credentials.waba_number;
        this.account_dtls.channel =
          value.channel_credentials.channel_name.toLowerCase();
      }
    });

    if (environment.sso) {
      this.deleterolesdata('profile_management');
      this.store
        .select(selectSsoProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.loggedInUserDetails) {
            this.userDetails = res.loggedInUserDetails;
          }
        });
    } else {
      this.store
        .select(selectProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.loggedInUserDetails) {
            this.userDetails = res.loggedInUserDetails;
          }
        });
    }
    this.store
      .select(selectRoleDataDtls)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (
          (res.createRoleRes && res.createRoleRes.status_code === 201) ||
          (res.updateroleRes && res.updateroleRes.status_code === 200)
        ) {
          this.displayTemplate = this.createRoleSuccess;
          this.createRole.reset();
          this.rolePermissions = JSON.parse(
            JSON.stringify(customerPermissionsData)
          );
        } else if (res.viewroleRes && res.viewroleRes.status_code === 200) {
          this.updateRoleItem = res.viewroleRes.data;
          this.createRole.patchValue({
            roleName: this.updateRoleItem.role_name,
            roleDesc: this.updateRoleItem.desc,
          });
          this.isUpdateRole = true;
          this.updateRoleItem = JSON.parse(JSON.stringify(this.updateRoleItem));
          if (typeof this.updateRoleItem.rbac_policies === 'string') {
            this.rolePermissions = JSON.parse(
              this.updateRoleItem.rbac_policies
            );
          } else {
            this.rolePermissions = this.updateRoleItem.rbac_policies;
          }
        } else if (res.error) {
          const dispErr = res.error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      });
  }

  get rFC() {
    return this.createRole.controls;
  }

  ngAfterViewInit(): void {
    this.displayTemplate = this.createRoleTemplate;
    this.cd.detectChanges();
  }

  deleterolesdata(key: any) {
    delete this.rolePermissions[key];
    this.rolesAclList.next(Object.keys(this.rolePermissions));
  }

  isObject(val: any): boolean {
    return typeof val === 'object';
  }

  submitCreateRole() {
    if (this.createRole.invalid) {
      return;
    }

    const selectedChannel = this.channelList.find((t) => t.isdefault === true);
    const createpayload = {
      role_name: this.createRole.value.roleName,
      desc: this.createRole.value.roleDesc,
      account_id: this.account_dtls.account_id,
      account_type: 'CUSTOMER',
      channel:
        selectedChannel.ch_name === 'WhatsApp Business API' ? 'WABA' : '',
      is_active: true,
      rbac_policies: this.rolePermissions,
      //this.updaterolePermissions(),
    };

    this.store.dispatch(
      roleactions.initiateCreateRole({ data: createpayload })
    );
  }

  updaterolePermissions() {
    // Array of keys to include in the new object
    const keysToInclude = this.rolesAclList.value;

    // Create a new object with only the specified keys
    const modifiedObject: any = {};

    keysToInclude.forEach((key) => {
      if (this.rolePermissions.hasOwnProperty(key)) {
        modifiedObject[key] = this.rolePermissions[key];
      }
    });

    return modifiedObject;
  }

  submitUpdateRole() {
    if (this.createRole.invalid) {
      return;
    }

    const selectedChannel = this.channelList.find((t) => t.isdefault === true);
    const updatepayload = {
      role_name: this.createRole.value.roleName,
      desc: this.createRole.value.roleDesc,
      account_id: this.account_dtls.account_id,
      account_type: this.updateRoleItem.account_type,
      channel:
        selectedChannel.ch_name === 'WhatsApp Business API' ? 'WABA' : '',
      is_active: true,
      rbac_policies: this.rolePermissions,
    };

    this.store.dispatch(
      roleactions.updateRoleDetails({
        id: this.updateRoleItem.id,
        payload: updatepayload,
      })
    );
  }

  cancelCreateRole() {
    this.gotoListing();
    this.createRole.reset();
    this.rolePermissions = JSON.parse(JSON.stringify(customerPermissionsData));
  }

  getListControls(data: any) {
    const payload = data;
    const permissionObj = [];
    for (const key in payload) {
      if (
        payload.hasOwnProperty(key) &&
        payload[key].hasOwnProperty('full_control') &&
        payload[key].full_control === true
      ) {
        permissionObj.push(key);
      }
      if (payload.hasOwnProperty(key) && payload[key] === true) {
        permissionObj.push(key);
      }
    }
    const totalcount = Object.keys(payload).length;
    const permissionscount = permissionObj.length;

    return `${permissionscount}/${totalcount}`;
  }

  // Function to update "Full Control" based on individual permissions
  updateFullControl(section: string) {
    const objPermissions = this.rolePermissions[section];
    // If all object permissions are true, set Full Control to true
    if (this.rolePermissions[section].full_control) {
      objPermissions.can_create = true;
      objPermissions.can_view = true;
      objPermissions.can_update = true;
      objPermissions.can_delete = true;
    }

    // If Full Control is unchecked, uncheck all object permissions
    if (!this.rolePermissions[section].full_control) {
      objPermissions.can_create = false;
      objPermissions.can_view = false;
      objPermissions.can_update = false;
      objPermissions.can_delete = false;
    }
  }

  updateIndividualPermissions(section: string) {
    const objPermissions = this.rolePermissions[section];

    if (
      objPermissions.can_create &&
      objPermissions.can_view &&
      objPermissions.can_update &&
      objPermissions.can_delete
    ) {
      objPermissions.full_control = true;
    } else {
      objPermissions.full_control = false;
    }
  }

  checkPermissions(data: any, value: any) {
    let list: any = [];
    const cc_list: string[] = [
      'acl_management',
      'profile_management',
      'audit_logs',
      'control_centre_dashboard_analytics',
      'control_centre_bsp_analytics',
    ];
    if (environment.sso) {
      cc_list.splice(1, 1);
    }
    if (value === 'control_centre' && !data) {
      list = this.rolesAclList.value.filter(
        (t) =>
          t !== 'acl_management' &&
          t !== 'profile_management' &&
          t !== 'audit_logs' &&
          t !== 'control_centre_dashboard_analytics' &&
          t !== 'control_centre_bsp_analytics'
      );
      this.enableProperties(cc_list, false);
      this.rolesAclList.next(list);
    }
    if (value === 'control_centre' && data) {
      list = this.rolesAclList.value;
      list = list.concat(cc_list);
      this.enableProperties(cc_list, true);
      this.rolesAclList.next(list);
    }
  }

  //generating payload
  enableProperties(properties: string[], value: boolean) {
    properties.forEach((property) => {
      if (property in this.rolePermissions) {
        if (typeof this.rolePermissions[property] === 'object') {
          for (const key in this.rolePermissions[property]) {
            if (this.rolePermissions[property].hasOwnProperty(key)) {
              this.rolePermissions[property][key] = value;
            }
          }
        } else {
          this.rolePermissions[property] = value;
        }
      }
    });
  }

  onEditRole(data: any) {
    this.updateRoleItem = data;
    this.createRole.patchValue({
      roleName: data.role_name,
      roleDesc: data.desc,
    });
    this.isUpdateRole = true;
    if (typeof data.rbac_policies === 'string') {
      this.rolePermissions = JSON.parse(data.rbac_policies);
    } else {
      this.rolePermissions = data.rbac_policies;
    }
    this.displayTemplate = this.createRoleTemplate;
  }

  gotoListing() {
    this.router.navigate(['cc', this.userDetails.profile.id, 'roles']);
    this.createRole.reset();
    this.isUpdateRole = false;
  }

  deleteRole(data: any) {
    this.store.dispatch(
      roleactions.initiateDeleteRole({
        roleid: data.id,
      })
    );
  }

  inactivate(item: any) {
    const updatepayload = {
      role_name: item.role_name,
      desc: item.desc,
      account_id: item.account_id,
      account_type: item.account_type,
      is_active: item.is_active,
      rbac_policies: this.rolePermissions,
    };

    this.store.dispatch(
      roleactions.updateRoleDetails({
        id: item.id,
        payload: updatepayload,
      })
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
