import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AllBSPs, allBSPs } from '../profiles-management.data';
import { SelectionModel } from '@angular/cdk/collections';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, Subscription, takeUntil } from 'rxjs';
import * as profileactions from 'src/app/control-center/profiles-management/store/profiles.actions';
import { selectProfileActionsData } from 'src/app/control-center/profiles-management/store/profiles.selectors';
import { Role } from 'src/app/appdata/roles.model';
import { TableProfileData } from 'src/app/appdata/profiledata.model';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/alert/alert.service';
import { RoleVariables, SharedService } from 'src/app/services/shared.service';
import { selectRoleDataDtls } from 'src/app/control-center/role-management/store/role.selectors';
import { selectProfilesDtlsData } from 'src/app/store/common.selectors';
import { ProfileVariables } from 'src/app/services/profilemanagement.service';
import {
  AuthUserData,
  ProfileData,
  RbacPolicies,
} from 'src/app/appdata/auth.model';
import { skeleton_data } from 'src/app/customer/whatsapp-preview/whatsapp-preview.data';
import * as roleactions from 'src/app/control-center/role-management/store/role.actions';

@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.scss'],
})
export class ProfileListComponent implements OnInit, AfterViewInit, OnDestroy {
  displayTemplate: TemplateRef<any>;
  deleteItem: any;
  active = 1;
  isCopied: boolean = false;
  roleList: Role[] = [];
  tabledata = new BehaviorSubject<TableProfileData[]>([]);
  totalProfilesList: number = 0;
  bspsList: AllBSPs[] = allBSPs;
  channel_credentails: Array<any> = [];
  page = 1;
  pageSize = 10;
  searchData: string = '';
  permissions: RbacPolicies;
  skeletonData = skeleton_data;
  profileIndex: number;
  destroy$: Subject<boolean> = new Subject<boolean>();
  selection = new SelectionModel<TableProfileData>(true, []);
  userDetails: AuthUserData;
  profileDataSubscription: Subscription;
  isLoading = true;
  shortName: any;

  filterCriteria: any = {
    bsp: null,
    bs_acc_no: null,
    role: null,
  };

  displayedColumns: string[] = [
    'name',
    'email',
    'status',
    'role',
    'channels',
    'bsp',
    'accounts',
    // 'actions',
  ];

  account_dtls: ProfileVariables = {
    accountid: null,
    bspid: null,
    wabano: null,
    channel: null,
    profileName: null,
    role: null,
    createdby: null,
    page: this.page,
    size: this.pageSize,
    startdate: null,
    enddate: null,
  };

  @ViewChild('noTemplates') private noTemplates: TemplateRef<any>;
  @ViewChild('datawaiting') private datawaiting: TemplateRef<any>;
  @ViewChild('allProfilesTemp') private allProfilesTemplate: TemplateRef<any>;

  constructor(
    private readonly store: Store,
    private cd: ChangeDetectorRef,
    private alertMsg: AlertService,
    private modalService: NgbModal,
    private router: Router,
    public shareservice: SharedService
  ) {
    this.shortName = this.shareservice.getShortName;
  }

  ngOnInit(): void {
    this.initiateUserProfileSub();
    this.initiateProfileActonsSub();
    this.initiateRoleDataSub();
    this.initiateDatePickerSub();
  }

  initiateUserProfileSub() {
    this.store
      .select(selectProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.loggedInUserDetails) {
          this.userDetails = res.loggedInUserDetails;
          this.permissions = this.userDetails.profile.acl.rbac.rbac_policies;
          this.channel_credentails =
            res.loggedInUserDetails.account.licenses.license_data;
          const abac_policies = this.userDetails.profile.acl.abac.abac_policies;
          let activeaccount: any;
          if (abac_policies.waba_policies.length > 0) {
            activeaccount = this.userDetails.account.licenses.license_data.find(
              (t: any) =>
                t.channel_credentials.waba_number ===
                abac_policies.waba_policies[0].waba_number
            );
          }
          this.account_dtls.accountid = activeaccount.account_id;
          this.account_dtls.channel = activeaccount.channel.toLowerCase();
          // this.account_dtls.bspid = activeaccount.channel_credentials.bsp_id;
          // this.account_dtls.wabano =
          //   activeaccount.channel_credentials.waba_number;
          this.account_dtls.createdby = this.userDetails.profile.id;
          this.store.dispatch(
            profileactions.fetchProfileslist({
              payload: this.account_dtls,
            })
          );
        }
      });
  }

  initiateProfileActonsSub() {
    this.store
      .select(selectProfileActionsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.profilesList) {
          if (
            res.profilesList.status_code === 200 &&
            res.profilesList.data.profiles.length > 0
          ) {
            this.isLoading = false;
            const table_data: any = [];
            res.profilesList.data.profiles.forEach((t: any) => {
              table_data.push({
                id: t.id,
                profile_image_url: t.profile_image_url,
                first_name: t.first_name,
                last_name: t.last_name,
                username: t.username,
                is_active: t.is_active,
                email: t.email,
                mobile_number: t.mobile_number,
                account_id: t.account_id,
                designation: t.designation,
                roles: this.getRolesData(t),
                toolTipText: this.toolTipText(this.getRolesData(t)),
                channels: this.getChannelsList(t),
                bsps: this.getChannelsList(t),
                business_accounts: this.getBusinessAccounts(t),
                created_at: t.created_at,
                updated_at: t.updated_at,
                deleted_at: t.deleted_at,
              });
            });
            this.tabledata.next(table_data);
            this.totalProfilesList = res.profilesList.data.count;
          } else {
            this.tabledata.next([]);
            this.totalProfilesList = 0;
          }
        } else if (res.delete_res && res.delete_res.status_code === 201) {
          this.modalService.dismissAll();
          this.alertMsg.alertSuccess('Success!', res.delete_res.message);
          this.store.dispatch(
            profileactions.fetchProfileslist({
              payload: this.account_dtls,
            })
          );
        } else if (res.error) {
          this.isLoading = false;
          this.modalService.dismissAll();
          const dispErr = res.error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      });
  }

  initiateRoleDataSub() {
    this.store
      .select(selectRoleDataDtls)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.roleList) {
          this.roleList = JSON.parse(JSON.stringify(res.roleList.data.rbacs));
        } else if (res.error) {
          const dispErr = res.error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      });
  }

  initiateDatePickerSub() {
    this.profileDataSubscription = this.shareservice.datePickerObject.subscribe(
      (value) => {
        if (value) {
          this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));
          if (value.from && value.to) {
            const fromDate: NgbDateStruct = value.from;
            const toDate: NgbDateStruct = value.to;
            this.account_dtls.startdate = `${fromDate.year}-${fromDate.month}-${fromDate.day}`;
            this.account_dtls.enddate = `${toDate.year}-${toDate.month}-${toDate.day}`;
            this.store.dispatch(
              profileactions.fetchProfileslist({
                payload: this.account_dtls,
              })
            );
          }
          if (value.action === 'Date Cleared') {
            this.account_dtls.startdate = null;
            this.account_dtls.enddate = null;
            this.store.dispatch(
              profileactions.fetchProfileslist({
                payload: this.account_dtls,
              })
            );
          }
        }
      }
    );
  }

  //Method to get the Roles List associated with a profile
  getRolesData(data: any) {
    if (data.acl && data.acl.rbac_list.length > 0) {
      const filteredArr = data.acl.rbac_list.filter((i: any) => i !== null);
      return filteredArr;
      // return data.acl.rbac_list;
    } else {
      return [];
    }
  }

  ngAfterViewInit(): void {
    this.displayTemplate = this.allProfilesTemplate;
    this.cd.detectChanges();
  }

  //Method to check if filters are applied or not based on this boolean value is returned
  checkFilteredItems() {
    const obj = this.filterCriteria;

    const isNullish = Object.values(obj).every((value) => {
      if (value === null) {
        return true;
      }

      return false;
    });

    return isNullish;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tabledata.value?.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.tabledata.value);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: TableProfileData): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id + 1
    }`;
  }

  //Method to navigate to create profile page
  onCreateProfile() {
    this.router.navigate([
      'cc',
      this.userDetails.profile.id,
      'profiles',
      'create',
    ]);
  }

  //Method to navigate to update profile page
  onUpdateProfile(data: ProfileData) {
    this.router.navigate([
      'cc',
      this.userDetails.profile.id,
      'profiles',
      data.id,
      'edit',
    ]);
  }

  //Method to delete profile view page
  deleteProfile() {
    this.store.dispatch(
      profileactions.DeleteProfileActions.deleteProfile({
        accountid: this.account_dtls.accountid,
        profile_id: this.deleteItem.id,
      })
    );
  }

  //Method to navigate to profile view page
  onViewProfile(data: ProfileData) {
    if (this.permissions.profile_management.can_view) {
      this.router.navigate([
        'cc',
        this.userDetails.profile.id,
        'profiles',
        data.id,
        'view',
      ]);
    }
  }

  //Method to get the Channels List associated with a profile
  getChannelsList(data: any) {
    if (data.acl && data.acl.abac) {
      return this.shareservice.getchannelsList(data.acl.abac.abac_policies);
    } else {
      return [];
    }
  }

  //Method to get the Business accounts info for a profile
  getBusinessAccounts(data: any) {
    if (data.acl && data.acl.abac) {
      const abac_policies = data.acl.abac.abac_policies;
      const activeaccount: Array<any> = [];
      if (abac_policies.waba_policies.length > 0) {
        abac_policies.waba_policies.forEach((t: any) => {
          const presentacct =
            this.userDetails.account.licenses.license_data.find(
              (m: any) => m.channel_credentials.waba_number === t.waba_number
            );
          if (presentacct) {
            activeaccount.push(presentacct);
          }
        });
      }
      return activeaccount;
    } else {
      return [];
    }
  }

  openModal(content: TemplateRef<string>, data: string, item?: any) {
    if (data === 'deleteTemplate') {
      this.deleteItem = item;
    }
    this.modalService.open(content, {
      centered: true,
      scrollable: true,
      backdrop: 'static',
    });
  }

  getFiltersData() {
    const rolePayload: RoleVariables = {
      accountid: this.account_dtls.accountid,
      bspid: null,
      wabano: null,
      channel: null,
      page: 1,
      size: 20,
      name: null,
      startdate: null,
      enddate: null,
    };
    this.store.dispatch(roleactions.fetchRoleslist({ payload: rolePayload }));
  }

  clearFilter(action: string) {
    this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));
    if (action === 'bsp') {
      this.filterCriteria.bsp = null;
    } else if (action === 'bsaccno') {
      this.account_dtls.wabano = null;
      this.filterCriteria.bs_acc_no = null;
    } else if (action === 'role') {
      this.account_dtls.role = null;
      this.filterCriteria.role = null;
    } else if (action === 'clearfilter') {
      this.account_dtls.wabano = null;
      this.account_dtls.role = null;
      this.filterCriteria.bsp = null;
      this.filterCriteria.bs_acc_no = null;
      this.filterCriteria.role = null;
    }

    this.checkFilteredItems();
    this.store.dispatch(
      profileactions.fetchProfileslist({
        payload: this.account_dtls,
      })
    );
  }

  //Method to apply fiter
  applyFilter() {
    this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));
    this.account_dtls.wabano = this.filterCriteria.bs_acc_no
      ? this.filterCriteria.bs_acc_no.channel_credentials.waba_number
      : null;
    this.account_dtls.role = this.filterCriteria.role
      ? this.filterCriteria.role.role_name
      : null;
    this.store.dispatch(
      profileactions.fetchProfileslist({
        payload: this.account_dtls,
      })
    );
  }

  stopPropagation(event: any) {
    event.stopPropagation();
  }

  //Pagination Event Method
  onPageEvent(event: any) {
    this.page = event;
    this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));
    this.account_dtls.page = this.page;
    this.store.dispatch(
      profileactions.fetchProfileslist({
        payload: this.account_dtls,
      })
    );
  }

  //Method to search
  onSearchNameChange(event: any) {
    if (!event) {
      this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));
      this.account_dtls.profileName = null;
      this.store.dispatch(
        profileactions.fetchProfileslist({
          payload: this.account_dtls,
        })
      );
    } else {
      this.searchName(event);
    }
  }

  // Function to get list of roles to show in tooltip
  toolTipText(data: any) {
    const filteredArr = data.filter((i: any) => i !== null);
    let toolTip = '';
    filteredArr.forEach((i: any, j: number) => {
      if (i && i?.role_name && j != 0 && data.length > 1) {
        toolTip += i.role_name;
        if (filteredArr.length - 1 != j) {
          toolTip += ' , ';
        }
      }
    });
    return toolTip;
  }

  searchName(data: string) {
    if (data && data.length > 2) {
      this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));
      this.account_dtls.profileName = data.trim();
      this.store.dispatch(
        profileactions.fetchProfileslist({
          payload: this.account_dtls,
        })
      );
    }
  }

  handleImageError(event: any) {
    // Set the src attribute of the img tag to the default avatar image path
    event.target.src = 'assets/img/customer/default_avatar.jpg';
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.tabledata.unsubscribe();
    this.profileDataSubscription.unsubscribe();
    this.shareservice.datePickerObject.next(null);
  }
}
