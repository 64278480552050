import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ControlCenterComponent } from './control-center.component';
import { ProfilesManagementComponent } from './profiles-management/profiles-management.component';
import { RoleManagementComponent } from './role-management/role-management.component';
import { ControlCenterDashboardComponent } from './dashboard/dashboard.component';
import { BusinessAccountsComponent } from './business-accounts/business-accounts.component';
import { CcSupportComponent } from './cc-support/cc-support.component';
import { BspComponent } from './bsp/bsp.component';
import { AuthGuard } from '../services/auth.guard';
import { ProfileListComponent } from './profiles-management/profile-list/profile-list.component';
import { AddProfileComponent } from './profiles-management/add-profile/add-profile.component';
import { ViewProfileComponent } from './profiles-management/view-profile/view-profile.component';
import { RoleListComponent } from './role-management/role-list/role-list.component';
import { AddRoleComponent } from './role-management/add-role/add-role.component';
import { PermissionsGuard } from '../services/permissions.guard';
import { EditProfileComponent } from './profiles-management/edit-profile/edit-profile.component';
import { ViewRoleComponent } from './role-management/view-role/view-role.component';
import { ServiceAccountsComponent } from './service-accounts/service-accounts.component';

const routes: Routes = [
  {
    path: '',
    component: ControlCenterComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: ':id/dashboard',
        component: ControlCenterDashboardComponent,
        canActivate: [PermissionsGuard],
      },
      {
        path: ':id/bsp',
        component: BspComponent,
      },
      {
        path: ':id/business-accounts',
        component: BusinessAccountsComponent,
        canActivate: [PermissionsGuard],
      },
      {
        path: ':id/service-accounts',
        component: ServiceAccountsComponent,
        canActivate: [PermissionsGuard],
      },
      {
        path: ':id/view-service-account',
        component: ServiceAccountsComponent,
        canActivate: [PermissionsGuard],
      },
      {
        path: ':id/profiles',
        component: ProfilesManagementComponent,
        children: [
          { path: '', component: ProfileListComponent },
          { path: 'create', component: AddProfileComponent },
          { path: ':id/edit', component: EditProfileComponent },
          { path: ':id/view', component: ViewProfileComponent },
        ],
        canActivate: [PermissionsGuard],
      },
      {
        path: ':id/roles',
        component: RoleManagementComponent,
        children: [
          { path: '', component: RoleListComponent },
          { path: 'create', component: AddRoleComponent },
          { path: ':id/edit', component: AddRoleComponent },
          { path: ':id/view', component: ViewRoleComponent },
        ],
        canActivate: [PermissionsGuard],
      },
      {
        path: ':id/support',
        component: CcSupportComponent,
        canActivate: [PermissionsGuard],
      },
      // {
      //   path: ':id/white-label',
      //   component: WhiteLabelingComponent,
      // },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ControlCenterRoutingModule {}
