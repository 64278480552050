<!-- Edit Profiles -->
<div class="card">
  <div class="top_header_options justify-content-start gap-2">
    <!-- Goto Profiles List -->
    <button
      class="btn btn-sm btn-icon btn-light"
      (click)="onCancelEditProfile()"
    >
      <em class="bi bi-chevron-left"></em>
    </button>
    <!-- END Goto Profiles List -->

    <h3 class="main_title">Edit Profile</h3>
  </div>
</div>

<div class="card card-vh-100 mt-2">
  <!-- Main Content Container -->
  <ng-container [ngTemplateOutlet]="displayTemplate"></ng-container>
  <!-- END Main Content Container -->
</div>
<!-- END Edit Profiles -->

<!-- Edit Profile Form -->
<ng-template #editProfileForm>
  <div class="Edit_form_sec w-80 m-auto">
    <!-- Basic Information -->
    <div class="card mb-4">
      <div class="card-body">
        <h4 class="title mb-4">Basic Information</h4>

        <form
          [formGroup]="basicDetailsForm"
          (ngSubmit)="onBasicDtlsSubmit(basicDetailsForm)"
        >
          <!-- Avatar Upload -->
          <div class="avatar avatar-circle avatar-xl avatar-uploader">
            <!-- Upload Avatar -->
            <ng-container
              *ngIf="
                !basicDetailsForm.get('profileImg').value;
                else profileIconTemp
              "
            >
              <img
                id="avatarImg"
                class="avatar-img"
                src="assets/img/customer/default_avatar.jpg"
                alt="User Avatar"
              />

              <span class="avatar-uploader-trigger">
                <em
                  class="bi-camera-fill avatar-uploader-icon shadow-soft avatar_icon"
                  (click)="fileinput.click()"
                  (keyup)="fileinput.click()"
                ></em>
                <input
                  type="file"
                  accept="image/*"
                  class="d-none"
                  id="mp_upload_input"
                  name="userImage"
                  (change)="onAvatarUpload($event)"
                  (click)="$event.target.value = null"
                  #fileinput
                />
              </span>
            </ng-container>
            <!-- END Upload Avatar -->

            <!-- Remove Uploaded Avatar -->
            <ng-template #profileIconTemp>
              <a class="delete avatar-uploader" (click)="onAvatarRemove()">
                <em class="bi-trash-fill"></em>
              </a>
              <img
                id="avatarImg"
                class="avatar-img"
                [src]="basicDetailsForm.get('profileImg').value"
                [alt]="shortName(basicDetailsForm.get('firstName').value+' '+basicDetailsForm.get('lastName').value) | uppercase"
              />
            </ng-template>
            <!-- END Remove Uploaded Avatar -->
          </div>
          <!-- END Avatar Upload -->

          <!-- Full Name -->
          <div class="form-group mt-3">
            <label class="input-label" for="fullname"
              >Fullname
              <span class="text-danger" matTooltip="Full Name is mandatory"
                >*</span
              >
            </label>

            <div class="row row-cols-2">
              <!-- First Name -->
              <div class="col">
                <input
                  class="form-control"
                  type="text"
                  id="fullname"
                  placeholder="First Name"
                  formControlName="firstName"
                />
                <!-- Profile Firstname Error -->
                <div class="invalid-feedback">
                  <div
                    *ngIf="
                      epBasicFC['firstName'].touched ||
                      epBasicFC['firstName'].dirty
                    "
                  >
                    <ng-container
                      *ngIf="
                        epBasicFC['firstName'].value === '' ||
                          epBasicFC['firstName'].value === null;
                        else fcInvalid
                      "
                      >First name can not be empty.</ng-container
                    >
                    <ng-template #fcInvalid>
                      <span *ngIf="epBasicFC['firstName'].errors"
                        >Please enter a valid first name.</span
                      >
                    </ng-template>
                  </div>
                </div>
                <!-- END Profile Firstname Error -->
              </div>
              <!-- END First Name -->

              <!-- Last Name -->
              <div class="col">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Last Name"
                  formControlName="lastName"
                />
                <!-- Profile LastName Error -->
                <div class="invalid-feedback">
                  <div
                    *ngIf="
                      epBasicFC['lastName'].touched ||
                      epBasicFC['lastName'].dirty
                    "
                  >
                    <ng-container
                      *ngIf="
                        epBasicFC['lastName'].value === '' ||
                          epBasicFC['lastName'].value === null;
                        else fcInvalid
                      "
                      >Last name can not be empty.</ng-container
                    >
                    <ng-template #fcInvalid>
                      <span *ngIf="epBasicFC['lastName'].errors"
                        >Please enter a valid last name.</span
                      >
                    </ng-template>
                  </div>
                </div>
                <!-- END Profile LastName Error -->
              </div>
              <!-- END Last Name -->
            </div>
          </div>
          <!-- ENd Full Name -->

          <!-- Email & Mobile -->
          <div class="form-group">
            <div class="row row-cols-2">
              <!-- Email -->
              <div class="col">
                <label class="input-label" for="email"
                  >Business Email ID
                  <span
                    class="text-danger"
                    matTooltip="Business Email ID is mandatory"
                    >*</span
                  >
                </label>
                <input
                  class="form-control"
                  type="email"
                  id="email"
                  placeholder="Enter business email-id here"
                  formControlName="email"
                />
                <!-- Profile Email Error -->
                <div class="invalid-feedback">
                  <div
                    *ngIf="
                      epBasicFC['email'].touched || epBasicFC['email'].dirty
                    "
                  >
                    <ng-container
                      *ngIf="
                        epBasicFC['email'].value === '' ||
                          epBasicFC['email'].value === null;
                        else fcInvalid
                      "
                      >Business email id can not be empty.</ng-container
                    >
                    <ng-template #fcInvalid>
                      <span *ngIf="epBasicFC['email'].errors"
                        >Please enter a valid business email id.</span
                      >
                    </ng-template>
                  </div>
                </div>
                <!-- END Profile Email Error -->
              </div>
              <!-- END Email -->

              <!-- Mobile Number -->
              <div class="col">
                <label class="input-label" for="mobile"
                  >Mobile Number
                  <span
                    class="text-danger"
                    matTooltip="Mobile Number is mandatory"
                    >*</span
                  >
                </label>
                <input
                  class="form-control"
                  id="mobile"
                  type="tel"
                  placeholder="Enter mobile no here"
                  formControlName="mob_no"
                />
                <!-- My Profile Mobile Number Error -->
                <div class="invalid-feedback">
                  <div
                    *ngIf="
                      epBasicFC['mob_no'].touched || epBasicFC['mob_no'].dirty
                    "
                  >
                    <ng-container
                      *ngIf="
                        epBasicFC['mob_no'].value === '' ||
                          epBasicFC['mob_no'].value === null;
                        else fcInvalid
                      "
                      >Mobile number can not be empty.</ng-container
                    >
                    <ng-template #fcInvalid>
                      <span *ngIf="epBasicFC['mob_no'].errors"
                        >Please enter a valid mobile number.</span
                      >
                    </ng-template>
                  </div>
                </div>
                <!-- END My Profile Mobile Number Error -->
              </div>
              <!-- END Mobile Number -->
            </div>
          </div>
          <!-- END Email & Mobile -->

          <!-- Username & Designation -->
          <div class="form-group">
            <div class="row row-cols-2">
              <!-- Username -->
              <div class="col">
                <label class="input-label" for="userName"
                  >User Name
                  <span class="text-danger" matTooltip="Username is mandatory"
                    >*</span
                  >
                </label>
                <input
                  class="form-control"
                  id="userName"
                  type="text"
                  placeholder="Enter user name here"
                  formControlName="username"
                  autocomplete="off"
                />
                <!-- My Profile User Name Error -->
                <div class="invalid-feedback">
                  <div
                    *ngIf="
                      epBasicFC['username'].touched ||
                      epBasicFC['username'].dirty
                    "
                  >
                    <ng-container
                      *ngIf="
                        epBasicFC['username'].value === '' ||
                          epBasicFC['username'].value === null;
                        else fcInvalid
                      "
                      >Username can not be empty.</ng-container
                    >
                    <ng-template #fcInvalid>
                      <span *ngIf="epBasicFC['username'].errors"
                        >Please enter a valid username.</span
                      >
                    </ng-template>
                  </div>
                </div>
                <!-- END My Profile User Name Error -->
              </div>
              <!-- END Username -->
              <!-- Designation -->
              <div class="col">
                <label class="input-label" for="designation"
                  >Designation(Optional)</label
                >
                <input
                  class="form-control"
                  id="designation"
                  type="text"
                  placeholder="Enter Designation"
                  formControlName="designation"
                />
              </div>
              <!-- END Designation -->
            </div>
          </div>
          <!-- END Username & Designation -->

          <!-- Divider -->
          <mat-divider class="my-4"></mat-divider>
          <!-- END Divider -->

          <!-- Footer Buttons -->
          <div class="form_action_btns">
            <div class="options_left"></div>
            <div class="options_right">
              <button
                class="btn btn-outline-dark"
                type="button"
                (click)="onCancelEditProfile()"
              >
                Cancel
              </button>
              <button
                class="btn btn-gradient"
                type="submit"
                [disabled]="basicDetailsForm.invalid"
              >
                Update Changes
                <i class="bi-chevron-right ms-1"></i>
              </button>
            </div>
          </div>
          <!-- END Footer Buttons -->
        </form>
      </div>
    </div>
    <!-- END Basic Information -->

    <!-- Password Change -->
    <div class="card mb-4">
      <div class="card-body">
        <h4 class="title mb-4">Change Password</h4>

        <form
          [formGroup]="changePwdForm"
          (ngSubmit)="onPswdSubmit(changePwdForm)"
        >
          <!-- Password & Confirm Password -->
          <div class="form-group">
            <div class="row row-cols-2">
              <!-- Password -->
              <div class="col">
                <label class="input-label" for="newpwd"
                  >New Password
                  <span class="text-danger" matTooltip="Password is mandatory"
                    >*</span
                  >
                </label>
                <div class="input-group input-group-merge"> 
                  <input
                    formControlName="pass"
                    class="form-control"
                    [type]="showNewPass ? 'text' : 'password'"
                    placeholder="Enter New password"
                    aria-label="password"
                    name="newPassword"
                    id="newpwd"
                    autocomplete="off"
                  />
                  <div class="input-group-append input-group-text changePassTarget">
                    <em
                      class="changePassIcon bi"
                      [ngClass]="{
                        'bi-eye-fill': !showNewPass,
                        'bi-eye-slash-fill': showNewPass
                      }"
                      (click)="showNewPass = !showNewPass"
                      (keydown)="showNewPass = !showNewPass"
                    >
                    </em>
                  </div>
                </div>
                <!-- Profile New Password Error -->
                <div class="invalid-feedback">
                  <div *ngIf="epPwdFC['pass'].touched || epPwdFC['pass'].dirty">
                    <ng-container
                      *ngIf="epPwdFC['pass'].value === ''; else fnInvalid"
                    >
                      Please enter valid New Password.
                    </ng-container>

                    <ng-template #fnInvalid>
                      <span *ngIf="epPwdFC['pass'].value.length < 8">
                        Password length should be minimum 8 characters
                      </span>
                      <div
                        *ngIf="
                          epPwdFC['pass'].value.length >= 8 &&
                          epPwdFC['pass'].errors
                        "
                      >
                        Password should contain atleast
                        <span *ngIf="epPwdFC['pass'].hasError('hasSmallCase')"
                          >1 Lowercase</span
                        >
                        <span *ngIf="epPwdFC['pass'].hasError('hasCapitalCase')"
                          >1 Uppercase</span
                        >
                        <span *ngIf="epPwdFC['pass'].hasError('hasNumber')"
                          >, 1 Numeric value</span
                        >
                        <span
                          *ngIf="
                            epPwdFC['pass'].hasError('hasSpecialCharacters')
                          "
                          >, 1 Special Character</span
                        >.
                      </div>
                    </ng-template>
                  </div>
                </div>
                <!-- END Profile New Password Error -->
              </div>
              <!-- END Password -->

              <!-- Confirm Password -->
              <div class="col">
                <label class="input-label" for="cnfrmpwd"
                  >Confirm Password
                  <span
                    class="text-danger"
                    matTooltip="Confirm Password is mandatory"
                    >*</span
                  >
                </label>
                <div class="input-group input-group-merge">
                  <input
                    formControlName="cnfm_pass"
                    [type]="showConfPass ? 'text' : 'password'"
                    class="form-control"
                    placeholder="confirm password"
                    aria-label="confirm password"
                    name="confirmPassword"
                    id="cnfrmpwd"
                    autocomplete="off"
                    data-msg="Your confirm password is invalid. Please try again."
                  />
                  <div class="input-group-append input-group-text changePassTarget">
                    <em
                      class="changePassIcon bi"
                      [ngClass]="{
                        'bi-eye-fill': !showConfPass,
                        'bi-eye-slash-fill': showConfPass
                      }"
                      (click)="showConfPass = !showConfPass"
                      (keydown)="showConfPass = !showConfPass"
                    >
                    </em>
                  </div>
                </div>
                <!-- Profile Confirm New Password Error -->
                <div class="invalid-feedback">
                  <div
                    *ngIf="
                      epPwdFC['cnfm_pass'].touched || epPwdFC['cnfm_pass'].dirty
                    "
                  >
                    <ng-container
                      *ngIf="
                        epPwdFC['cnfm_pass'].value === '' ||
                          epPwdFC['cnfm_pass'].value === null;
                        else fcInvalid
                      "
                    >
                      Please enter valid Confirm Password.
                    </ng-container>
                    <ng-template #fcInvalid>
                      <span
                        *ngIf="
                          epPwdFC['cnfm_pass'].value !== epPwdFC['pass'].value
                        "
                      >
                        Password and Confirm Password does not match.
                      </span>
                    </ng-template>
                  </div>
                </div>
                <!-- END Profile Confirm New Password Error -->
              </div>
              <!-- END Confirm Password -->
            </div>
          </div>
          <!-- END Password & Confirm Password -->

          <!-- Divider -->
          <mat-divider class="my-4"></mat-divider>
          <!-- END Divider -->

          <!-- Footer Buttons -->
          <div class="form_action_btns">
            <div class="options_left"></div>
            <div class="options_right">
              <button
                class="btn btn-outline-dark"
                type="button"
                (click)="onCancelEditProfile()"
              >
                Cancel
              </button>
              <button
                class="btn btn-gradient"
                type="submit"
                [disabled]="changePwdForm.invalid"
              >
                Update Password
                <i class="bi-chevron-right ms-1"></i>
              </button>
            </div>
          </div>
          <!-- END Footer Buttons -->
        </form>
      </div>
    </div>
    <!-- END Password Change -->

    <!-- ACL Permissions -->
    <div class="card mb-4">
      <div class="card-body">
        <h4 class="title mb-4">ACL Permissions</h4>

        <form [formGroup]="aclPermForm" (ngSubmit)="onAclSubmit(aclPermForm)">
          <div class="channels">
            <h5 class="mb-3">Select Channel</h5>
            <nav ngbNav #nav="ngbNav" class="nav-tabs">
              <ng-container ngbNavItem>
                <button ngbNavLink>WhatsApp</button>
                <ng-template ngbNavContent>
                  <div class="form-group">
                    <div class="row row-cols-2">
                      <!-- Role Selection -->
                      <div class="col">
                        <div class="input-label label"
                          >Roles
                          <span
                            class="text-danger"
                            matTooltip="Role is mandatory"
                            >*</span
                          >
                        </div>
                        <ng-select
                          [placeholder]="'Select Role'"
                          [items]="roleList"
                          [multiple]="true"
                          [searchable]="true"
                          groupBy="selectedAllGroup"
                          [selectableGroup]="true"
                          [selectableGroupAsModel]="false"
                          formControlName="role"
                          (change)="onRolesSelect($event)"
                          [closeOnSelect]="false"
                          name="role"
                          id="roleSelection"
                        >
                          <ng-template
                            ng-multi-label-tmp
                            let-items="items"
                            let-clear="clear"
                          >
                            <div
                              class="ng-value"
                              *ngFor="let item of items | slice : 0 : 2"
                            >
                              <span class="ng-value-label">
                                {{ item.role_name }}</span
                              >
                              <span
                                class="ng-value-icon right"
                                (click)="clear(item)"
                                (keydown)="clear(item)"
                                aria-hidden="true"
                                >×</span
                              >
                            </div>
                            <div
                              class="ng-value"
                              *ngIf="aclPermForm.get('role').value.length > 2"
                            >
                              <span class="ng-value-label"
                                >{{
                                  aclPermForm.get("role").value.length - 2
                                }}
                                more...</span
                              >
                            </div>
                          </ng-template>
                          <ng-template
                            ng-optgroup-tmp
                            let-item="item"
                            let-item$="item$"
                            let-index="index"
                          >
                            <input
                              id="item-{{ index }}"
                              type="checkbox"
                              [ngModel]="item$.selected"
                              [ngModelOptions]="{ standalone: true }"
                            />
                            Select All
                          </ng-template>

                          <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-item$="item$"
                            let-index="index"
                          >
                            <input
                              id="item-{{ index }}"
                              type="checkbox"
                              [ngModel]="item$.selected"
                              [ngModelOptions]="{ standalone: true }"
                            />
                            {{ item.role_name }}
                          </ng-template>
                        </ng-select>

                        <!-- Role Error -->
                        <div class="invalid-feedback">
                          <div
                            *ngIf="
                              epAclFC['role'].touched || epAclFC['role'].dirty
                            "
                          >
                            <ng-container
                              *ngIf="
                                epAclFC['role'].value === '' ||
                                epAclFC['role'].value === null
                              "
                              >Please select a role.</ng-container
                            >
                          </div>
                        </div>
                        <!-- END Role Error -->
                      </div>
                      <!-- END Role Selection -->
                      <!-- Active Role Selection -->
                      <div class="col" *ngIf="aclPermForm.get('role')?.value">
                        <span class="input-label" for="activerole"
                          >Active Role
                          <span class="text-danger" matTooltip="Set Active Role"
                            >*</span
                          >
                      </span>
                        <ng-select
                          [placeholder]="'Select Active Role'"
                          [items]="aclPermForm.get('role')?.value"
                          [multiple]="false"
                          [searchable]="false"
                          bindLabel="role_name"
                          formControlName="activerole"
                          [closeOnSelect]="true"
                          name="activerole"
                          id="activerole"
                        >
                        </ng-select>

                        <!-- Active Role Error -->
                        <div class="invalid-feedback">
                          <div
                            *ngIf="
                              epAclFC['activerole'].touched ||
                              epAclFC['activerole'].dirty
                            "
                          >
                            <ng-container
                              *ngIf="
                                epAclFC['activerole'].value === '' ||
                                epAclFC['activerole'].value === null
                              "
                              >Please select a Active role.</ng-container
                            >
                          </div>
                        </div>
                        <!-- END Active Role Error -->
                      </div>
                      <!-- END Active Role Selection -->
                    </div>
                  </div>

                  <!-- Select BSPs -->
                  <div class="form-group">
                    <div class="label input-label"
                      >Select BSP's
                      <span
                        class="text-danger"
                        matTooltip="BSP Selection is mandatory"
                        >*</span
                      >
                    </div>
                    <div class="bsp_list" *ngFor="let t of bsps">
                      <div class="card card-bg">
                        <div class="row row-cols-3">
                          <div class="col">
                            <div class="d-flex align-items-center gap-1">
                              <mat-checkbox
                                (change)="selectedBsps($event)"
                                class="list-checkboxes m-0"
                                id="bspSelection"
                                [value]="t.name"
                                [checked]="t.isChecked"
                              ></mat-checkbox>
                              <h4>{{t.name === 'RML' ? 'Routemobile' : t.name}}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- END Select BSPs -->

                  <!-- Select Business Accounts -->
                  <div class="form-group">
                    <div class="label input-label"
                      >Select Business Accounts
                      <span
                        class="text-danger"
                        matTooltip="Business Account Selection is mandatory"
                        >*</span
                      >
                    </div>
                  </div>

                  <div class="bsp_list">
                    <div class="card card-bg">
                      <div class="row row-cols-2">
                        <div class="col">
                          <div class="label input-label"
                            >Route Mobile</div
                          >
                          <mat-divider class="my-2"></mat-divider>

                          <!-- Listing of Business Acnts -->
                          <div
                            class="act d-flex align-items-center"
                            *ngFor="let t of wabanos; let i = index"
                          >
                            <mat-checkbox
                              class="list-checkboxes m-0"
                              (change)="selectedBAs($event)"
                              [value]="t.channel_credentials.waba_number"
                              *ngIf="t.bsp === 'RML'"
                              [checked]="t.isChecked"
                            ></mat-checkbox>
                            <div
                              class="view-bact d-flex align-items-center justify-content-between gap-2"
                              *ngIf="t.bsp === 'RML'"
                            >
                              <!-- Business Account Default Avatar -->
                              <div class="avatar avatar-circle avatar-md">
                                <img
                                  class="avatar-img"
                                  [src]="t.channel_credentials.profile_pic"
                                  [alt]="t.channel_credentials.business_name"
                                />
                              </div>
                              <!-- END Business Account Default Avatar -->

                              <div class="ba_details">
                                <h4 class="d-flex align-items-center gap-1">
                                  {{ t.channel_credentials.waba_number }}
                                </h4>
                                <p>
                                  {{
                                    t.channel_credentials.business_name
                                      | titlecase
                                  }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <!-- END Listing of Business Acnts -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- END Select Business Accounts -->
                </ng-template>
              </ng-container>
            </nav>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>

          <!-- Divider -->
          <mat-divider class="my-4"></mat-divider>
          <!-- END Divider -->

          <!-- Footer Buttons -->
          <div class="form_action_btns">
            <div class="options_left"></div>
            <div class="options_right">
              <button
                class="btn btn-outline-dark"
                type="button"
                (click)="onCancelEditProfile()"
              >
                Cancel
              </button>
              <button
                class="btn btn-gradient"
                type="submit"
                [disabled]="aclPermForm.invalid"
              >
                Update Changes
                <i class="bi-chevron-right ms-1"></i>
              </button>
            </div>
          </div>
          <!-- END Footer Buttons -->
        </form>
      </div>
    </div>
    <!-- END ACL Permissions -->
  </div>
</ng-template>
<!-- END Edit Profile Form -->

<!-- Edit Profile Success -->
<ng-template #editSuccess>
  <div class="success_screen">
    <img src="assets/img/success.svg" alt="Success" />
    <h4 class="my-2">Success!</h4>
    <p class="sub_title">User profile has been updated Successfully</p>

    <!-- Goto Template List Button -->
    <button
      type="button"
      class="btn btn-gradient mt-2"
      (click)="goToProfileList()"
    >
      Go to Profiles
    </button>
    <!-- END Goto Template List Button -->
  </div>
</ng-template>
<!-- END Edit Profile Success -->
